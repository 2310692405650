<template>
  <div>
    <Teleport
      to="#header-buttons"
    >
      <div id="tooltip-no-permissions">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="ml-2"
        >
          Size Guide
        </b-button>
      </div>
    </Teleport>
    <div>
      <b-skeleton-wrapper :loading="spinner">
        <template #loading>
          <b-skeleton width="100%" />
          <b-skeleton width="75%" />
          <b-skeleton width="50%" />
        </template>
        <b-card
          class="card"
        >
          <div class="bg-white d-flex justify-content-between">
            <div>
              <img
                src="../../assets/images/icons/canva.png"
                width="50"
                height="50"
                alt=""
              >
            </div>
            <div
              v-if="!accessTokenUser"
              class="d-flex align-items-center"
            >
              Get Started with Canva.
            </div>
            <div class="d-flex align-items-center">
              <b-button
                v-if="!accessTokenUser"
                variant="white"
                size="sm"
                block
                class="buttons"
                :disabled="btndisable"
                @click="getCodeChallenge"
              >
                <img
                  src="../../assets//images/icons/canvabtn.png"
                  class="imagebtn"
                  alt=""
                >
                Connect
              </b-button>
              <b-button
                v-if="accessTokenUser"
                variant="primary"
                class="mr-2 buttonCreateDesign"
                @click="goToCanva"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                Create a Design
              </b-button>
              <b-button
                v-if="accessTokenUser"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="white"
                class="buttons"
                :disabled="loader"
                @click="revokeAccess"
              >
                <div
                  v-if="loader"
                  class="modal-overlay"
                >
                  <b-spinner label="Loading..." />
                </div>
                <img
                  src="../../assets//images/icons/canvabtn.png"
                  class="imagebtn"
                  alt=""
                >
                Disconnect
              </b-button>
            </div>
          </div>
        </b-card>
        <div
          v-if="accessTokenUser"
          class="heading-elements"
        >
          <b-skeleton-table
            v-if="spinner"
            :rows="2"
            :columns="3"
            :table-props="{
              bordered: true,
              striped: true,
              tableClass: 'skeleton-loading-table',
            }"
          />
          <b-table
            v-else
            id="venues-table"
            :items="userDesigns"
            responsive
            :per-page="rows"
            :current-page="currentPage"
            :fields="tableColumns"
            show-empty
            hover
            selectable
            empty-text="No matching records found"
            class="float: right"
          >
            <!-- Column: Venue ID -->
            <template #cell(Thumbnail)="data">
              <span class="d-block text-nowrap devices">
                <img
                  :src="data.item.thumbnail ? data.item.thumbnail.url : null"
                  :width="50"
                  :height="50"
                  alt=""
                >
              </span>
            </template>
            <!-- Column: Venue Name -->
            <template #cell(Name)="data">
              <span class="title d-inline-block text-truncate">
                {{ data.item.title ? data.item.title : '...' }}
              </span>
            </template>
            <template #cell(Download)="data">
              <span class="no-wrap">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="white"
                  size="sm"
                  class="buttons"
                  @click="addMediaButton(data.item)"
                >
                  <img
                    src="../../assets//images/icons/canvabtn.png"
                    class="imagebtn"
                    alt=""
                  >
                  Add to Media
                </b-button>
              </span>
            </template>
            <template #cell(Action)="data">
              <span class="no-wrap d-flex flex-column">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="white"
                  size="sm"
                  class="buttons"
                  @click="viewWithCanva(data)"
                >
                  <img
                    src="../../assets//images/icons/canvabtn.png"
                    class="imagebtn"
                    alt=""
                  >
                  View in Canva
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="white"
                  size="sm"
                  class="buttons mt-1"
                  @click="editWithCanva(data)"
                >
                  <img
                    src="../../assets//images/icons/canvabtn.png"
                    class="imagebtn"
                    alt=""
                  >
                  Edit in Canva
                </b-button>
              </span>
            </template>
          </b-table>
        </div>
      </b-skeleton-wrapper>
    </div>
    <div class="mt-0 d-flex justify-content-end mr-5 mt-2">
      <div class="overflow-auto">
        <!-- pagination -->
        <b-pagination
          v-if="userDesigns"
          v-model="currentPage"
          :per-page="rows"
          pills
          :total-rows="rowslength"
          size="sm"
        />
      </div>
    </div>
    <AddMedia
      ref="add-media-modal"
      @getAllMediaStorage="getAllMediaStorageOfUser(paginationObject)"
    />
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BCard,
  BSkeletonTable,
  BSkeletonWrapper,
  BSkeleton,
  VBTooltip,
  BSpinner,
  BPagination,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import Teleport from 'vue2-teleport'
import AddMedia from '@/components/common/canvaAddMedia.vue'
import 'vuesax/dist/vuesax.css'
import { showToast } from '@/common/global/functions'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    BTable,
    BButton,
    BCard,
    BSkeletonTable,
    BSkeletonWrapper,
    BSkeleton,
    AddMedia,
    Teleport,
    BSpinner,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      code: null,
      canvaCode: null,
      codeVerifier: null,
      accessTokenUser: null,
      userDesigns: null,
      downloadURLS: null,
      spinner: false,
      loader: false,
      isUserCanvaAuthenticated: false,
      isValidAccessToken: null,
      rows: 5,
      currentPage: 1,
      btndisable: false,
      tableColumns: [
        { key: 'Thumbnail', label: 'Thumbnail' },
        { key: 'Name', label: 'Name' },
        {
          key: 'Download', label: 'Add to Media', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'Action', label: 'Action', thClass: 'text-center', tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    rowslength() {
      return this.userDesigns?.length || 0
    },
  },
  async mounted() {
    this.spinner = true
    await this.initializeCanvaIntegration()
    this.spinner = false
  },
  methods: {
    async initializeCanvaIntegration() {
      this.getAccessTokenFromLocal()
      await this.$store.dispatch('canva/getCanvaCode')
      this.canvaCode = this.$store.getters['canva/getCanvaCode']

      if (this.canvaCode && !this.accessTokenUser) {
        await this.exchangeCodeForAccessToken()
      } else if (this.accessTokenUser) {
        await this.getDesigns()
      }
    },
    async exchangeCodeForAccessToken() {
      const response = await this.$store.dispatch('canva/getAccessTokenExchangedAgainstCanvaCode', { canvaCode: this.canvaCode })
      this.accessTokenUser = this.$store.getters['canva/getAccessToken']
      if (response) {
        await this.getDesigns()
      } else {
        this.resetValuesAndAskUserToLogingAgain('Error Exchanging Token. Please try login again!')
      }
    },
    async getCodeChallenge() {
      this.btndisable = true
      const Challenge = await this.$axios.get('auth/canva')
      const { data } = Challenge.data
      this.btndisable = false
      this.connectWithCanva(data)
    },
    connectWithCanva(codeChallenge) {
      window.location.href = `https://www.canva.com/api/oauth/authorize?code_challenge_method=s256&response_type=code&client_id=${process.env.VUE_APP_CANVA_CLIENT_ID}&scope=app:read%20app:write%20design:content:read%20design:meta:read%20design:content:write%20design:permission:read%20design:permission:write%20folder:read%20folder:write%20folder:permission:read%20folder:permission:write%20asset:read%20asset:write%20comment:read%20comment:write%20brandtemplate:meta:read%20brandtemplate:content:read%20profile:read&code_challenge=${codeChallenge}`
    },
    goToCanva() {
      window.open('https://www.canva.com/', '_blank')
    },
    editWithCanva({ item }) {
      window.open(item.urls.edit_url, '_blank')
    },
    viewWithCanva({ item }) {
      window.open(item.urls.view_url, '_blank')
    },
    async revokeAccess() {
      this.loader = true
      this.getAccessTokenFromLocal()

      await this.$store.dispatch('canva/revokeAccess', {
        accessToken: this.accessTokenUser.access_token,
      })
      this.accessTokenUser = null
      this.loader = false
    },
    addMediaButton(data) {
      this.$refs['add-media-modal'].show(data)
    },
    async checkIfAccessTokenISValidElseRefresh() {
      const token = await this.$store.dispatch('canva/introspectToken', {
        accessToken: this.accessTokenUser.access_token,
      })
      if (!token) {
        this.resetValuesAndAskUserToLogingAgain('Error validating token. Please try login again!')
      }
      this.isValidAccessToken = this.$store.getters['canva/getIsValid']

      if (!this.isValidAccessToken.active) {
        const response = await this.$store.dispatch('canva/refreshToken', {
          refreshToken: this.accessTokenUser.refresh_token,
        })
        if (!response) {
          this.resetValuesAndAskUserToLogingAgain('Error refreshing token. Please try login again!')
        }
      }
      this.getAccessTokenFromLocal()
    },
    async getDesigns() {
      await this.checkIfAccessTokenISValidElseRefresh()

      const response = await this.$store.dispatch('canva/getDesigns', {
        accessTokenUser: this.accessTokenUser.access_token,
      })
      if (!response) {
        this.resetValuesAndAskUserToLogingAgain('Error getting designs. Please try login again!')
      }

      this.userDesigns = this.$store.getters['canva/getListOfDesigns']
      this.getAccessTokenFromLocal()
      this.canvaCode = this.$store.getters['canva/getCanvaCode']
    },
    getAccessTokenFromLocal() {
      this.accessTokenUser = JSON.parse(localStorage.getItem('canva-token'))
    },
    async resetValuesAndAskUserToLogingAgain(message) {
      showToast('Request Failed', message, 'danger')
      localStorage.removeItem('canva-token')
      localStorage.removeItem('canvaCode')
      this.getAccessTokenFromLocal()
      await this.$store.dispatch('canva/getCanvaCode')
      this.canvaCode = this.$store.getters['canva/getCanvaCode']
      this.spinner = false
    },
  },
}
</script>

<style scoped>
.card-body {
  padding: 0.75rem !important;
}
.title {
  max-width: 350px;
  min-width: 300px;
}
.buttons{
border: 0.5px solid gray;
padding-top: 6px;
padding-bottom: 6px;
}
.buttonCreateDesign{
  padding-top: 9px;
  padding-bottom: 12px;
  }
.imagebtn{
  width: 20px;
  border-radius: 50%;
  margin-bottom: 1px;
  margin-right: 3px;
}
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.416);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Ensure it sits above the modal content */
}
</style>
